import React from "react"
import MainFooter from "../../../footer/main_footer"
import MainHeader from "../../../main_header/main_header"
import process_improve from "../banner/static/proc_improve.webp"
import * as classes from "./process_improvement_training.module.scss"
import loadable from "@loadable/component"
const Banner = loadable(() => import("../banner/banner"), {
  fallback: <div></div>,
})
export default function ProcessImprovement() {
  return (
    <React.Fragment>
      <MainHeader />

      <Banner
        title={"Process Improvement Training"}
        backgroundImage={process_improve}
      />
      <div className={classes.text_div}>
        <h2>Process Improvement Training</h2>
        <p>
          While developing road maps for executing process improvement training,
          we make sure to implement 5 specific tools: flow charting, hypothesis
          testing, cause and effect analysis, value stream mapping, and process
          baselining and comparisons. Combining all these instruments according
          to your organization’s specific process needs is exactly what Master
          Trainers specializes in. We will help you develop the practical
          knowledge you need to improve organizational processes, address
          shortcomings, and drive business transformation. The transferable
          skills you will learn will assist you in designing processes that meet
          stakeholder needs, reach objectives, and efficiently manage
          operations.
        </p>
        <p>Process improvement training is for:</p>
        <ul>
          <li>Leaders and Managers</li>
          <li>Business professionals</li>
          <li>Project Managers</li>
          <li>Small Business Owners</li>
          <li>Business Improvement professionals</li>
          <li>Graduates</li>
        </ul>
      </div>
      <MainFooter />
    </React.Fragment>
  )
}
